import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  Button,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Import assets
import dogIcon from '../assets/Dog.png';
import catIcon from '../assets/Cat.png';
import animalsIcon from '../assets/Animals.png';
import landing1 from '../assets/Landing1.png';
import landing2 from '../assets/Landing2.png';
import pawicon from '../assets/PawAI.png';
import pawlogo from '../assets/Pawlogo.png';
import purin from '../assets/purin.png';
import chewy from '../assets/chewy.png';
import hills from '../assets/hills.png';
import medvet from '../assets/medvet.png';
import merck from '../assets/merck.png';
import instinct from '../assets/instinct.png';
import petsmart from '../assets/petsmart.png';

function LandingPage() {
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleLogin = () => navigate('/login');
  const handleSignup = () => navigate('/signup');

  return (
    <Box
      sx={{
        width: '100%',
        overflowX: 'hidden',
        boxSizing: 'border-box',
      }}
    >
      {/* HERO SECTION */}
      <Box
        component="section"
        sx={{
          position: 'relative',
          overflow: 'hidden',
          minHeight: '100vh',
          backgroundColor: '#F9FBFF',
          display: 'flex',
          flexDirection: 'column',
          pb: 30,
        }}
      >
        {/* Taskbar */}
        <Box
          sx={{
            width: '100%',
            height: '72px',
            px: '32px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: '#ffffff',
            borderBottom: '1px solid #ccc',
            zIndex: 1000,
          }}
        >
          <Box
            component="img"
            src={pawicon}
            alt="Paw AI Logo"
            sx={{ height: '60px', width: 'auto' }}
          />
          <Box sx={{ display: 'flex', gap: '16px' }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleLogin}
              sx={{
                width: '73px',
                height: '32px',
                borderRadius: '8px',
                textTransform: 'none',
                fontSize: '14px',
                marginLeft:'20px',
              }}
            >
              Log in
            </Button>
            <Button
              variant="contained"
              onClick={handleSignup}
              sx={{
                width: '86px',
                height: '32px',
                borderRadius: '8px',
                textTransform: 'none',
                fontSize: '14px',
                backgroundColor: '#4C64D9',
                marginRight:'50px',
              }}
            >
              Sign Up
            </Button>
          </Box>
        </Box>

        {/* Hero Content */}
        <Container
          maxWidth="lg"
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            textAlign: 'center',
            py: { xs: 4, md: 10 },
            mt: { xs: 8, md: 0 },
          }}
        >
          <Typography variant="h2" sx={{ fontWeight: 'bold', mb: 2 }}>
            Paw AI – The Future of Veterinary Diagnostics
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              mb: 4,
              maxWidth: '540px',
              opacity: 1,
              fontSize: '24px',
              fontWeight: 400,
              lineHeight: '32px',
              mx: 'auto',
            }}
          >
            Enhance your clinic’s diagnostics, offering advanced care and peace of mind.
          </Typography>

          <Box sx={{ mt: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={handleSignup}
              sx={{
                width: '213px',
                height: '48px',
                borderRadius: '16px',
                background: '#010D49E5',
                textTransform: 'none',
                fontSize: '16px',
              }}
            >
              Sign Up Now!
            </Button>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              onClick={handleLogin}
              sx={{
                width: '213px',
                height: '44px',
                borderRadius: '16px',
                textTransform: 'none',
                fontSize: '16px',
                border: '2px solid #010D49',
                mb: '20px',
              }}
            >
              Log In
            </Button>

            <Box sx={{ mb: '12px', display: 'flex', flexDirection: 'column', alignItems: 'center', maxWidth: '540px' }}>
              <Typography
                variant="body2"
                sx={{
                  width: '341px',
                  fontSize: '14px',
                  lineHeight: '16px',
                  fontFamily: 'Gochi Hand',
                  mb: '20px',
                }}
              >
                trusted by:
              </Typography>
              <Typography variant="body2" sx={{ width: '400px', fontSize: '14px', lineHeight: '17px' }}>
                Board Certified Veterinarian, Pet Owners, & all Animal lovers.
              </Typography>
            </Box>
          </Box>
        </Container>

        {/* Absolutely Positioned Images */}
        <Box
          component="img"
          src={landing1}
          alt="Landing Feature 1"
          sx={{ position: 'absolute', left: '5%', bottom: '10%', width: { xs: '200px', sm: '380px' }, zIndex: 2 }}
        />
        <Box
          component="img"
          src={landing2}
          alt="Landing Feature 2"
          sx={{ position: 'absolute', right: '0%', bottom: '20%', width: { xs: '200px', sm: '380px' } }}
        />
        <Box
          component="img"
          src={dogIcon}
          alt="Dog"
          sx={{
            position: 'absolute',
            left: 0,
            bottom: 0,
            width: { xs: '150px', sm: '300px' },
            maxWidth: '35%',
            ml: '50px',
            zIndex: 1,
            display: { xs: 'none', sm: 'block' },
          }}
        />
        <Box
          component="img"
          src={catIcon}
          alt="Cat"
          sx={{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: { xs: '150px', sm: '300px' },
            maxWidth: '35%',
            display: { xs: 'none', sm: 'block' },
          }}
        />
        <Box
          component="img"
          src={animalsIcon}
          alt="Animals"
          sx={{
            position: 'absolute',
            top: 120,
            left: '50%',
            transform: 'translateX(-50%)',
            width: { xs: '100px', sm: '200px' },
            maxWidth: '40%',
            display: { xs: 'none', sm: 'block' },
          }}
        />
      </Box>

      {/* TRUSTED BY SECTION */}
      <Box
        sx={{
          width: '100%',
          minHeight: '458px',
          py: '64px',
          px: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#FFFFFF',
        }}
      >
        <Typography variant="h4" gutterBottom>
          Trusted by
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '24px',
            flexWrap: 'wrap',
            mt: 2,
          }}
        >
          <Box component="img" src={purin} alt="Purina" sx={{ width: '122.44px', height: '62px' }} />
          <Box component="img" src={chewy} alt="Chewy" sx={{ width: '122.44px', height: '62px' }} />
          <Box component="img" src={hills} alt="Hills" sx={{ width: '122.44px', height: '62px' }} />
          <Box component="img" src={medvet} alt="MedVet" sx={{ width: '122.44px', height: '62px' }} />
          <Box component="img" src={merck} alt="Merck" sx={{ width: '122.44px', height: '62px' }} />
          <Box component="img" src={instinct} alt="Instinct" sx={{ width: '122.44px', height: '62px' }} />
          <Box component="img" src={petsmart} alt="PetSmart" sx={{ width: '122.44px', height: '62px' }} />
        </Box>
      </Box>

      {/* PAW AI DIFFERENCE SECTION */}
      <Box
        sx={{
          width: '100%',
          py: '64px',
          backgroundColor: '#F3F5FF',
          textAlign: 'center',
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontWeight: 'bold',
            mb: 3,
            fontSize: '32px',
            lineHeight: '40px',
            color: '#1E223A',
          }}
        >
          The PAW AI Difference
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            gap: '24px',
            mt: 4,
            mx: 'auto',
            px: 2,
          }}
        >
          {[
            {
              subtitle: 'Precision',
              title: 'AI Diagnostics',
              text: 'Our AI analyzes symptoms and health data to provide rapid, accurate diagnostic suggestions, helping veterinarians make informed decisions quickly and effectively.',
            },
            {
              subtitle: 'Efficient Patient Management',
              title: 'Streamlined Pet Care Workflow',
              text: 'Optimize your clinic’s operations with our system that manages appointments, tracks patient histories, and more—freeing up more time for hands-on pet care.',
            },
            {
              subtitle: 'AI X-Ray & Lab Analysis',
              title: 'Advanced Imaging & Test Analysis',
              text: 'Leverage our AI’s ability to quickly analyze X-rays and lab results, highlighting potential issues and providing detailed reports to support your clinical decision-making process.',
            },
          ].map(({ subtitle, title, text }, index) => (
            <Box
              key={index}
              sx={{
                width: { xs: '100%', sm: '300px' },
                padding: '24px',
                borderRadius: '12px',
                backgroundColor: '#FFFFFF',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.05)',
                textAlign: 'left',
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{
                  color: '#3F51B5',
                  fontWeight: 'bold',
                  mb: 1,
                  fontSize: '16px',
                }}
              >
                {subtitle}
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 'bold',
                  mb: 2,
                  fontSize: '20px',
                  color: '#1E223A',
                }}
              >
                {title}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: '#555',
                  fontSize: '16px',
                  lineHeight: '24px',
                }}
              >
                {text}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>

      {/* TESTIMONIALS SECTION */}
      <Box
        component="section"
        sx={{
          width: '100%',
          py: 8,
          px: 2,
          backgroundColor: '#FFFFFF',
          textAlign: 'center',
        }}
      >
        <Typography variant="h4" sx={{ mb: 4 }}>
          Testimonials
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{
            maxWidth: '800px',
            mb: 3,
            fontSize: '20px',
            fontWeight: 400,
            color: '#555',
            mx: 'auto',
          }}
        >
          “As a veterinarian, I am always looking for tools that 
          <strong> enhance the speed and efficiency</strong> of pet care. 
          PawAI has been <strong>a game-changer in my practice</strong>. 
          Its advanced features streamline routine tasks, allowing me to 
          focus more on the needs of my patients.”
        </Typography>
        <Box component="img" src={pawlogo} alt="Paw Logo" sx={{ width: '80px', mb: 4 }} />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 3,
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              width: '40px',
              height: '40px',
              border: '2px solid #3C3C6C',
              borderRadius: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
            }}
          >
            <Typography sx={{ color: '#3C3C6C' }}>{'<'}</Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Box
              sx={{
                width: '10px',
                height: '10px',
                borderRadius: '50%',
                backgroundColor: '#3C3C6C',
              }}
            />
            <Box
              sx={{
                width: '10px',
                height: '10px',
                borderRadius: '50%',
                backgroundColor: '#D0D0D0',
              }}
            />
            <Box
              sx={{
                width: '10px',
                height: '10px',
                borderRadius: '50%',
                backgroundColor: '#D0D0D0',
              }}
            />
          </Box>
          <Box
            sx={{
              width: '40px',
              height: '40px',
              border: '2px solid #3C3C6C',
              borderRadius: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
            }}
          >
            <Typography sx={{ color: '#3C3C6C' }}>{'>'}</Typography>
          </Box>
        </Box>
      </Box>

      {/* DEMO CTA SECTION */}
      <Box
        component="section"
        sx={{
          width: '100%',
          py: 8,
          px: 2,
          backgroundColor: '#FFFFFF',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            width: '100%',
            maxWidth: '960px',
            borderRadius: '16px',
            overflow: 'hidden',
            boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.1)',
          }}
        >
          <Box
            component="img"
            src={require('../assets/demo.png')}
            alt="Demo"
            sx={{
              width: { xs: '100%', md: '50%' },
              height: 'auto',
              objectFit: 'cover',
            }}
          />
          <Box
            sx={{
              backgroundColor: '#1E3A8A',
              color: '#FFFFFF',
              width: { xs: '100%', md: '50%' },
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              p: 4,
              textAlign: 'center',
            }}
          >
            <img
              src={require('../assets/calendar.png')}
              alt="Calendar Icon"
              style={{ width: '48px', height: '48px', marginBottom: '16px' }}
            />
            <Typography
              variant="h6"
              sx={{
                fontSize: '20px',
                fontWeight: 'bold',
                mb: 2,
              }}
            >
              PawPilot AI: Demo Meeting
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontSize: '16px',
                lineHeight: '24px',
                mb: 3,
              }}
            >
              Discover how PawPilot AI revolutionizes veterinary care by analyzing
              blood work in just 10 seconds, compared to the traditional 5–10 minutes.
            </Typography>
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#4C64D9',
                color: '#FFFFFF',
                textTransform: 'none',
                fontSize: '16px',
                borderRadius: '12px',
                px: 3,
                py: 1.5,
              }}
            >
              Book Your Call Now!
            </Button>
          </Box>
        </Box>
      </Box>

      {/* ABOUT/FOUNDERS SECTION */}
      <Box
        component="section"
        sx={{
          width: '100%',
          py: '80px',
          px: 2,
          backgroundColor: '#FFFFFF',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            fontSize: '16px',
            textTransform: 'uppercase',
            color: '#6B7280',
            mb: 1,
            fontWeight: '500',
          }}
        >
          Introducing
        </Typography>
        <Typography
          variant="h4"
          sx={{
            fontSize: '32px',
            fontWeight: 'bold',
            color: '#1E223A',
            mb: 6,
          }}
        >
          Who We Are
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gap: '32px',
            alignItems: 'flex-start',
            justifyContent: 'center',
            maxWidth: '1200px',
            width: '100%',
          }}
        >
          <Box
            component="img"
            src={require('../assets/founder.png')}
            alt="Founder and Team"
            sx={{
              width: { xs: '100%', md: '50%' },
              height: 'auto',
              borderRadius: '8px',
              objectFit: 'cover',
            }}
          />
          <Box
            sx={{
              width: { xs: '100%', md: '50%' },
              display: 'flex',
              flexDirection: 'column',
              gap: '32px',
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontSize: '18px',
                lineHeight: '28px',
                color: '#4A5568',
              }}
            >
              We’re more than just a tech company—we’re a team with a deep connection
              to veterinary care. Our mission is to make veterinarians' lives easier
              by providing instant, AI-powered insights to enhance their
              decision-making, ultimately improving animal health and clinic
              efficiency.
            </Typography>
            <Box>
              <Typography
                variant="h6"
                sx={{
                  fontSize: '20px',
                  fontWeight: 'bold',
                  color: '#1E223A',
                  mb: 1,
                }}
              >
                Bryan – Founder
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontSize: '16px',
                  lineHeight: '24px',
                  color: '#4A5568',
                  mb: 2,
                }}
              >
                As the founder of PawPilot AI, I bring a background in software and
                technology, with a focus on simplifying complex workflows. My
                experience in data management and AI technology has driven me to
                create a solution that empowers veterinary practices to provide even
                better care for their patients.
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontSize: '16px',
                  lineHeight: '24px',
                  color: '#4A5568',
                }}
              >
                I’m passionate about leveraging technology to solve real-world
                problems and making sure our platform is as user-friendly and
                effective as possible.
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="h6"
                sx={{
                  fontSize: '20px',
                  fontWeight: 'bold',
                  color: '#1E223A',
                  mb: 1,
                }}
              >
                Melissa, DVM – Certified Veterinarian
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontSize: '16px',
                  lineHeight: '24px',
                  color: '#4A5568',
                  mb: 2,
                }}
              >
                Melissa is not only my fiancée but also a highly experienced
                veterinarian. She brings firsthand knowledge of the challenges that
                veterinarians face every day, from diagnosing complex cases to
                managing the operational side of a practice. Her deep understanding
                of veterinary medicine shapes every aspect of PawPilot AI, ensuring
                that the platform truly addresses the needs of the professionals who
                use it.
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontSize: '16px',
                  lineHeight: '24px',
                  color: '#4A5568',
                }}
              >
                Together, we combine technology and veterinary expertise to build a
                tool that not only improves clinical outcomes but also makes
                day-to-day tasks easier for veterinary teams.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* FAQ SECTION */}
      <Box
        component="section"
        sx={{
          width: '100%',
          py: 8,
          background: '#0015800A',
        }}
      >
        <Container
          sx={{
            width: '100%',
            maxWidth: '960px',
            textAlign: 'center',
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontSize: '32px',
              fontWeight: 'bold',
              mb: 2,
              color: 'inherit',
            }}
          >
            Frequently Asked Questions
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              fontSize: '18px',
              mb: 4,
              color: 'inherit',
            }}
          >
            Get help anytime – contact our concierge for support.
          </Typography>
          <Box>
            {[
              {
                question: 'What is Paw AI?',
                answer:
                  'Paw AI is an AI-driven platform designed to help veterinarians streamline their workflows and deliver more accurate care.',
                panel: 'panel1',
              },
              {
                question: 'How does Paw AI improve diagnostic accuracy?',
                answer:
                  'Our machine learning algorithms analyze large datasets of veterinary cases for fast, accurate diagnostics.',
                panel: 'panel2',
              },
              {
                question: 'Can Paw AI integrate with existing veterinary management systems?',
                answer:
                  'Yes, Paw AI is designed to seamlessly integrate with most veterinary management systems to enhance workflow efficiency.',
                panel: 'panel3',
              },
              {
                question: 'How secure is the data stored on Paw AI?',
                answer:
                  'Paw AI ensures top-level data security, complying with industry standards to protect sensitive information.',
                panel: 'panel4',
              },
              {
                question: 'Is Paw AI suitable for all types of veterinary practices?',
                answer:
                  'Yes, Paw AI is adaptable for practices of all sizes, providing solutions for both small clinics and larger animal hospitals.',
                panel: 'panel5',
              },
            ].map(({ question, answer, panel }) => (
              <Accordion
                key={panel}
                expanded={expanded === panel}
                onChange={handleChange(panel)}
                sx={{
                  background: '#0015800A',
                  borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                  '&:before': { display: 'none' },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  sx={{
                    padding: '0 16px',
                    '& .MuiAccordionSummary-content': { margin: 0 },
                  }}
                >
                  <Typography variant="body1" sx={{ fontWeight: '500', color: 'inherit' }}>
                    {question}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: '8px 16px', color: 'inherit' }}>
                  <Typography variant="body2">{answer}</Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </Container>
      </Box>

      {/* COPYRIGHT SECTION */}
      <Box
        component="footer"
        sx={{
          width: '100%',
          py: 8,
          px: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: '#FFFFFF',
          borderTop: '1px solid #E5E7EB',
        }}
      >
        <Box
          component="img"
          src={pawlogo}
          alt="Paw AI Logo"
          sx={{
            width: '60px',
            height: '60px',
            mb: 2,
          }}
        />
        <Typography
          variant="body2"
          sx={{
            color: '#6B7280',
            textAlign: 'center',
            mb: 2,
          }}
        >
          © 2024 PawAI. All Rights Reserved.
        </Typography>
        <Box sx={{ display: 'flex', gap: '24px' }}>
          <Typography
            variant="body2"
            component="a"
            href="/privacy-policy"
            sx={{
              color: '#6B7280',
              textDecoration: 'none',
              '&:hover': { textDecoration: 'underline' },
            }}
          >
            Privacy Policy
          </Typography>
          <Typography
            variant="body2"
            component="a"
            href="/terms-of-use"
            sx={{
              color: '#6B7280',
              textDecoration: 'none',
              '&:hover': { textDecoration: 'underline' },
            }}
          >
            Terms of Use
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default LandingPage;
