import React, { useState, useEffect } from 'react';
import { HashRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import AI_Assistant from './AI_Assistant';
// Removed: import WelcomePage from './components/WelcomePage';
import LandingPage from './components/LandingPage'; // New Landing Page
import LoginPage from './components/LoginPage';
import SignUpPage from './components/SignupPage';
import AccountInfoPage from './components/AccountInfo';
import PaymentPage from './components/PaymentPage';
import PaymentSuccess from './components/PaymentSuccess';
import { Box, CircularProgress, Typography } from '@mui/material';
import axios from 'axios';
import Taskbar from './components/Taskbar';

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isAuthChecked, setIsAuthChecked] = useState(false);
  const [hasSubscription, setHasSubscription] = useState(false);
  const [isFetchingSubscription, setIsFetchingSubscription] = useState(false);

  const fetchSubscriptionStatus = async () => {
    const token = localStorage.getItem('access_token');
    if (token) {
      try {
        console.log(`${process.env.REACT_APP_API_URL}/auth/user/organization`);
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/pawai-backend2/auth/user/organization`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setIsLoggedIn(true);
        setHasSubscription(response.data.has_subscription);
      } catch (error) {
        console.error("Error fetching subscription data:", error);
        if (error.response && error.response.status === 401) {
          handleLogout();
        }
      } finally {
        setIsAuthChecked(true);
        setIsFetchingSubscription(false);
      }
    } else {
      setIsAuthChecked(true);
      setIsFetchingSubscription(false);
    }
  };

  useEffect(() => {
    fetchSubscriptionStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogin = () => {
    fetchSubscriptionStatus();
  };

  const handleLogout = () => {
    localStorage.removeItem('access_token');
    setIsLoggedIn(false);
    setHasSubscription(false);
  };

  if (!isAuthChecked || isFetchingSubscription) {
    return (
      <Box 
        sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'center', 
          height: '100vh' 
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (

      <Router>
        <Box
          sx={{
            width: '100vw',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: '#F3F5FF',
            overflow: 'hidden',
          }}
        >
          {/* Taskbar */}
          {isLoggedIn && (
            <Box sx={{ width: '100%', height: '72px', flexShrink: 0 }}>
              <Taskbar isLoggedIn={isLoggedIn} handleLogout={handleLogout} />
            </Box>
          )}
    
          {/* Main Content Area */}
          <Box
            sx={{
              flex: 1,
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              overflow: 'hidden',
              marginTop: isLoggedIn ? '5px' : '0',
            }}
          >
            <Routes>
              {/* New Landing Page */}
              <Route 
                path="/" 
                element={<LandingPage />} 
              />
    
              <Route 
                path="/login" 
                element={
                  isLoggedIn ? (
                    <Navigate to="/ai-assistant" replace />
                  ) : (
                    <LoginPage onLogin={handleLogin} />
                  )
                } 
              />
    
              <Route 
                path="/signup" 
                element={<SignUpPage onSignUp={handleLogin} />} 
              />
    
              <Route
                path="/ai-assistant"
                element={
                  isLoggedIn ? (
                    <Box
                      sx={{
                        width: '100%',
                        height: 'calc(100vh - 77px)',
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'stretch',
                      }}
                    >
                      <Box
                        sx={{
                          filter: hasSubscription ? 'none' : 'blur(4px)',
                          pointerEvents: hasSubscription ? 'auto' : 'none',
                          width: '100%',
                          height: '100%',
                          overflow: 'hidden',
                        }}
                      >
                        <AI_Assistant />
                      </Box>
    
                      {!hasSubscription && (
                        <Box
                          sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            zIndex: 1,
                          }}
                        >
                          <PaymentPage />
                        </Box>
                      )}
                    </Box>
                  ) : (
                    <Navigate to="/login" replace />
                  )
                }
              />
    
              <Route 
                path="/payment-success" 
                element={<PaymentSuccess onCheckSubscription={() => fetchSubscriptionStatus()} />} 
              />
    
              <Route 
                path="/payment" 
                element={<PaymentPage />} 
              />
    
              <Route 
                path="/account-info" 
                element={
                  isLoggedIn ? (
                    <AccountInfoPage />
                  ) : (
                    <Navigate to="/login" replace />
                  )
                } 
              />
    
              <Route 
                path="/help" 
                element={<Typography variant="h4">Help Page</Typography>} 
              />
            </Routes>
          </Box>
        </Box>
      </Router>
    );
    
};

export default App;
