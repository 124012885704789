import React, { useState, useEffect, useCallback } from 'react';
import Sidebar from './components/Sidebar';
import ChatWindow from './components/ChatWindow';
import ChatBox from './components/ChatBox';
import { Box } from '@mui/material';
import axios from 'axios';

const AI_Assistant = () => {
  const [sessions, setSessions] = useState([]);
  const [currentSessionId, setCurrentSessionId] = useState(localStorage.getItem('currentSessionId') || null);
  const [chatMessages, setChatMessages] = useState(JSON.parse(localStorage.getItem('chatMessages')) || []);
  const [patientData, setPatientData] = useState(null);
  const [sessionMessages, setSessionMessages] = useState({});
  const [loadingSessions, setLoadingSessions] = useState(true);
  const [viewMode, setViewMode] = useState('patientForm'); 
  const [pdfFile, setPdfFile] = useState(null);

  const fetchSessionsAndMessages = useCallback(async () => {
    const token = localStorage.getItem('access_token');
    if (!token) {
      console.error("No token found, redirecting to login.");
      return;
    }
  
    setLoadingSessions(true);
    try {
      const sessionsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/pawai-backend2/conversations/`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      let fetchedSessions = sessionsResponse.data;
  
      fetchedSessions.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      setSessions(fetchedSessions);
  
      const messageMap = {};
      await Promise.all(
        fetchedSessions.map(async (session) => {
          try {
            const messageResponse = await axios.get(
              `${process.env.REACT_APP_API_URL}/pawai-backend2/conversations/${session.session_id}`,
              { headers: { Authorization: `Bearer ${token}` } }
            );
            const firstMessage = messageResponse.data?.[0]?.content || 'No message';
            messageMap[session.session_id] = firstMessage;
          } catch (msgError) {
            console.error(`Error fetching messages for session ${session.session_id}:`, msgError);
            messageMap[session.session_id] = 'Error loading message';
          }
        })
      );
      setSessionMessages(messageMap);
    } catch (error) {
      console.error('Error fetching sessions or messages:', error);
      if (error.response) {
        // Server responded with a status other than 2xx
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
        console.error('Response headers:', error.response.headers);
      } else if (error.request) {
        // Request was made but no response received
        console.error('Request made but no response:', error.request);
      } else {
        // Something else caused the error
        console.error('Error message:', error.message);
      }
      if (error.response && error.response.status === 401) {
        alert("Unauthorized access. Redirecting to login.");
        localStorage.removeItem("access_token");
        window.location.href = "/";
      }
    } finally {
      setLoadingSessions(false);
    }
  }, []);
  
  useEffect(() => {
    localStorage.setItem('viewMode', viewMode);
    localStorage.setItem('currentSessionId', currentSessionId);
    localStorage.setItem('chatMessages', JSON.stringify(chatMessages));
  }, [viewMode, currentSessionId, chatMessages]);

  useEffect(() => {
    fetchSessionsAndMessages();
  }, [fetchSessionsAndMessages]);

  const handlePatientDataSubmit = async (formData, conversationTitle, file) => {
    setPdfFile(file); 
    const token = localStorage.getItem('access_token');
    if (!token) {
      alert("Please log in again.");
      return;
    }

    try {
      const data = new FormData();
      data.append('pet_name', formData.pet_name);
      data.append('species', formData.species);
      data.append('breed', formData.breed);
      data.append('age', formData.age);
      data.append('weight', formData.weight);
      data.append('sex', formData.sex);
      data.append('conversation', conversationTitle);

      if (formData.concerns) {
        data.append('concerns', formData.concerns);
      }
      if (file instanceof File) {
        data.append('file', file);
      }

      const response = await axios.post(`${process.env.REACT_APP_API_URL}/pawai-backend2/sessions/`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      const newSession = response.data;
      setCurrentSessionId(newSession.session_id);
      setSessions((prevSessions) => [newSession, ...prevSessions]);

      await fetchSessionsAndMessages();
      setViewMode('chat');
      return newSession.session_id;
    } catch (error) {
      console.error('Error creating new conversation:', error);
    }
  };

  const handleConversationSelect = async (sessionId) => {
    const token = localStorage.getItem('access_token');
    try {
      const [messageResponse] = await Promise.all([
        axios.get(`${process.env.REACT_APP_API_URL}/pawai-backend2/conversations/${sessionId}`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
      ]);

      setChatMessages(messageResponse.data || []);
      setCurrentSessionId(sessionId);
      setViewMode('chat');
    } catch (error) {
      console.error('Error fetching conversation or patient info:', error);
    }
  };

  const handleNewConsultation = () => {
    setViewMode('patientForm');
    setCurrentSessionId(null);
    setChatMessages([]);
    setPatientData(null);
    setPdfFile(null);
    localStorage.removeItem('currentSessionId');
    localStorage.removeItem('chatMessages');
    localStorage.setItem('viewMode', 'patientForm');
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: 'calc(100vh - 80px)',
        display: 'flex',
        alignItems: 'stretch',
        overflow: 'hidden',
      }}
    >
      <Box sx={{ marginLeft: '5px' }}></Box>
      <Sidebar
        sessions={sessions}
        sessionMessages={sessionMessages}
        onSelectConversation={handleConversationSelect}
        onNewConsultation={handleNewConsultation}
        loading={loadingSessions}
        activeSessionId={currentSessionId}
      />

      {/* Wrapper Container for ChatWindow and ChatBox */}
      <Box
  sx={{
    width: '74%',
    height: '100%',          
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    overflow: 'hidden',
    marginLeft:'5px',
  }}
>
        {/* Render ChatWindow or ChatBox based on viewMode */}
        {viewMode === 'patientForm' ? (
          <ChatWindow
            sessionId={currentSessionId}
            chatMessages={chatMessages}
            patientData={patientData}
            onPatientDataSubmit={handlePatientDataSubmit}
            viewMode={viewMode}
            handleViewMode={setViewMode}
          />
        ) : (
          <ChatBox
            sessionId={currentSessionId}
            chatMessages={chatMessages}
            pdfFile={pdfFile}  
          />
        )}
      </Box>
    </Box>
  );
};

export default AI_Assistant;
