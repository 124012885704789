import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import {
  Box,
  Typography,
  CircularProgress,
  List,
  ListItem,
  IconButton,
} from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ChatInput from './ChatInput';
import { v4 as uuidv4 } from 'uuid'; // If using UUIDs for message IDs

const ChatBox = ({ sessionId, pdfFile }) => {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [feedback, setFeedback] = useState({}); // State to track feedback per message
  const messageEndRef = useRef(null);

  // Scroll to the bottom of the chat whenever messages update
  const scrollToBottom = () => {
    messageEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  // Fetch messages for the current session
  const fetchMessages = useCallback(async () => {
    const token = localStorage.getItem('access_token');
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/conversations/${sessionId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setMessages(response.data);

      const latestMessage = response.data[response.data.length - 1];
      if (latestMessage && latestMessage.sender === 'user') {
        await queryAI(latestMessage.content, pdfFile);
      }
    } catch (error) {
      console.error('Error fetching messages:', error);
      setError('Failed to fetch messages.');
    }
  }, [sessionId, pdfFile]);

  useEffect(() => {
    if (sessionId) {
      fetchMessages();
    }
  }, [sessionId, fetchMessages]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // Handle sending a new message
  const handleSend = async (inputMessage, file = pdfFile) => {
    if (!inputMessage.trim() && !file) return;

    const userMessage = {
      id: uuidv4(), // Generate a unique ID if not provided by backend
      session_id: sessionId,
      content: inputMessage,
      sender: 'user',
    };
    setMessages((prev) => [...prev, userMessage]);
    await saveMessage(userMessage);

    await queryAI(inputMessage, file);
  };

  // Query AI for a response
  const queryAI = async (question, file) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('question', question);
      formData.append('session_id', sessionId);

      if (file instanceof File) {
        formData.append('file', file);
      }

      const token = localStorage.getItem('access_token');
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/rag/`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      const aiMessage = {
        id: response.data.id || uuidv4(), // Ensure each message has a unique ID
        session_id: sessionId,
        content: response.data.response?.content?.content || 'No valid response from AI.',
        sender: 'AI',
      };

      setMessages((prev) => [...prev, aiMessage]);
      await saveMessage(aiMessage);
    } catch (error) {
      console.error('Error fetching AI response:', error);
      setError('Failed to fetch AI response.');
    } finally {
      setLoading(false);
    }
  };

  // Save a message to the backend
  const saveMessage = async (message) => {
    const token = localStorage.getItem('access_token');
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/conversations/`, message, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
    } catch (error) {
      console.error('Error saving message:', error);
      setError('Failed to save the message.');
    }
  };

  // Handle "Like" feedback
  const handleLike = async (messageId) => {
    setFeedback((prev) => ({ ...prev, [messageId]: 'like' }));

    // Optional: Send feedback to the backend
    try {
      const token = localStorage.getItem('access_token');
      await axios.post(
        `${process.env.REACT_APP_API_URL}/feedback/`,
        {
          session_id: sessionId,
          message_id: messageId,
          feedback: 'like',
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
    } catch (error) {
      console.error('Error sending like feedback:', error);
      // Optionally, revert the feedback state or notify the user
    }
  };

  // Handle "Dislike" feedback
  const handleDislike = async (messageId) => {
    setFeedback((prev) => ({ ...prev, [messageId]: 'dislike' }));

    // Optional: Send feedback to the backend
    try {
      const token = localStorage.getItem('access_token');
      await axios.post(
        `${process.env.REACT_APP_API_URL}/feedback/`,
        {
          session_id: sessionId,
          message_id: messageId,
          feedback: 'dislike',
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
    } catch (error) {
      console.error('Error sending dislike feedback:', error);
      // Optionally, revert the feedback state or notify the user
    }
  };

  // Render the content of each message
  const renderMessageContent = (content, messageId, sender) => {
    const lines = content.split('\n');
    let currentNumber = 0; // To track and generate correct numbering

    return (
      <Box sx={{ margin: '16px 0' }}>
        {lines.map((line, index) => {
          // Detect section headings or numbered list items (e.g., "1. Anemia:")
          if (/^\d+\.\s/.test(line.trim()) || /^\*\*.*\*\*:$/.test(line)) {
            currentNumber += 1; // Increment numbering correctly
            const formattedLine = line.replace(/^\d+\.\s|\*\*/g, ''); // Remove existing numbering and "**"
            return (
              <Typography
                key={index}
                sx={{
                  fontWeight: 'bold',
                  fontSize: '1.1rem',
                  marginBottom: '8px',
                  color: '#444',
                }}
              >
                {`${currentNumber}. ${formattedLine.trim()}`}
              </Typography>
            );
          }

          // Detect bullet points (e.g., "- Decreased red blood cell count...")
          if (line.trim().startsWith('-')) {
            return (
              <Typography
                key={index}
                sx={{
                  fontSize: '1rem',
                  marginLeft: '24px',
                  marginBottom: '8px',
                  color: '#555',
                  whiteSpace: 'pre-line',
                }}
              >
                {line.replace(/^- /, '').trim()} {/* Remove leading '-' */}
              </Typography>
            );
          }

          // General text (e.g., the concluding paragraph or introductory text)
          if (line.trim()) {
            return (
              <Typography
                key={index}
                sx={{
                  fontSize: '1rem',
                  marginBottom: '12px',
                  color: '#555',
                  whiteSpace: 'pre-line',
                }}
              >
                {line.trim()}
              </Typography>
            );
          }

          return null; // Ignore empty lines
        })}
      </Box>
    );
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        backgroundColor: '#FFFFFF',
        display: 'flex',
        flexDirection: 'column',
        padding: '24px',
        gap: '16px',
        borderRadius: '16px',
        boxShadow: '0px 4px 8px -2px rgba(0, 0, 0, 0.1)',
        boxSizing: 'border-box',
        overflow: 'hidden',
        margin: '0 auto',
      }}
    >
      {/* Messages List */}
      <Box
        sx={{
          flexGrow: 1,
          overflowY: 'auto',
          padding: '16px',
          maxWidth: '800px',
          margin: '0 auto',
        }}
      >
        <List>
          {messages.map((msg) => (
            <ListItem
              key={msg.id} // Ensure each message has a unique ID
              sx={{
                display: 'flex', // Use flex to align message and feedback buttons
                alignItems: 'flex-start', // Align items to the top
                justifyContent: msg.sender === 'AI' ? 'flex-start' : 'flex-end',
                padding: '0', // Remove default padding
                marginBottom: '16px', // Space between messages
              }}
            >
              {msg.sender === 'AI' && (
                <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                  {/* AI Message Bubble */}
                  <Box
                    sx={{
                      maxWidth: '70%', // Adjust as needed
                      backgroundColor: '#EFF1FD',
                      borderRadius: '12px',
                      padding: '12px 16px',
                      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                      wordWrap: 'break-word',
                    }}
                  >
                    {renderMessageContent(msg.content, msg.id, msg.sender)}
                  </Box>

                  {/* Feedback Buttons */}
                  <Box sx={{ display: 'flex', flexDirection: 'column', ml: '8px' }}>
                    <IconButton
                      aria-label="like"
                      size="small"
                      color={feedback[msg.id] === 'like' ? 'primary' : 'default'}
                      onClick={() => handleLike(msg.id)}
                      sx={{ mb: '4px' }}
                    >
                      <ThumbUpIcon fontSize='8px' />
                    </IconButton>
                    <IconButton
                      aria-label="dislike"
                      size="small"
                      color={feedback[msg.id] === 'dislike' ? 'error' : 'default'}
                      onClick={() => handleDislike(msg.id)}
                    >
                      <ThumbDownIcon fontSize="8px" />
                    </IconButton>
                  </Box>
                </Box>
              )}

              {msg.sender === 'user' && (
                <Box
                  sx={{
                    maxWidth: '70%', // Adjust as needed
                    background: 'linear-gradient(180deg, rgba(225, 183, 87, 0) -17.45%, rgba(225, 183, 87, 0.08) 100%)',
                    borderRadius: '12px',
                    padding: '12px 16px',
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                    wordWrap: 'break-word',
                  }}
                >
                  {renderMessageContent(msg.content, msg.id, msg.sender)}
                </Box>
              )}
            </ListItem>
          ))}
          {loading && (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <CircularProgress />
            </Box>
          )}
          {error && (
            <Typography color="error" sx={{ textAlign: 'center' }}>
              {error}
            </Typography>
          )}
          <div ref={messageEndRef}></div>
        </List>
      </Box>

      {/* Chat Input */}
      <ChatInput onSend={handleSend} />
    </Box>
  );
};

export default ChatBox;
