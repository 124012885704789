import React from 'react';
import {
  Box,
  Typography,
  Button,
  TextField,
  List,
  ListItem,
  Divider,
  InputAdornment,
  Link,
} from '@mui/material';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import searchIcon from '../assets/search.png';
import fileUploadIcon from '../assets/send.png';

const Sidebar = ({
  sessions = [],
  sessionMessages = {},
  onSelectConversation,
  onNewConsultation,
  loading,
  activeSessionId,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#FFFFFF',
        borderRadius: '16px',
        boxShadow: '0px 4px 8px -2px rgba(0, 0, 0, 0.1)',
        boxSizing: 'border-box',
        padding: 2,
        width: { xs: '100%', md: '25%' },
        maxHeight: '100%',
      }}
    >
      {/* Title Text */}
      <Typography
        variant="h6"
        sx={{
          fontWeight: '600',
          color: '#000626',
          mb: 2,
          fontSize: { xs: '1.2rem', md: '1.25rem' },
        }}
      >
        Previous Consultations
      </Typography>

      {/* Search Bar */}
      <TextField
        fullWidth
        placeholder="Search for previous consultations..."
        variant="outlined"
        sx={{
          borderRadius: '8px',
          backgroundColor: '#FFFFFF',
          mb: 2,
          '& .MuiOutlinedInput-root': {
            borderRadius: '8px',
            '& fieldset': {
              borderColor: '#000D4D73',
            },
          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Box
                component="img"
                src={searchIcon}
                alt="Search Icon"
                sx={{ width: 24, height: 24 }}
              />
            </InputAdornment>
          ),
        }}
      />

      {/* Start New Consultation Button */}
      <Button
        variant="outlined"
        onClick={onNewConsultation}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          gap: 1,
          borderRadius: '8px',
          color: '#4C64D9',
          border: '1px solid #4C64D9',
          textTransform: 'none',
          fontWeight: '500',
          fontSize: '16px',
          mb: 2,
          '&:hover': {
            backgroundColor: '#E3EFFF',
          },
        }}
      >
        <ChatBubbleOutlineIcon sx={{ fontSize: '20px' }} />
        Start a new consultation
      </Button>

      <Divider sx={{ my: 2, borderBottom: '1px dotted rgba(0, 9, 51, 0.65)' }} />

      {/* Removed Pinned Consultations Section */}

      {/* Recent Consultations Title */}
      <Typography
        variant="h6"
        sx={{
          fontWeight: '600',
          color: '#000933',
          mb: 2,
          fontSize: { xs: '1.1rem', md: '1.25rem' },
        }}
      >
        Recent Consultations
      </Typography>

      {/* Conversations List - takes majority of space */}
      <Box sx={{ flexGrow: 1, overflowY: 'auto', mb: 2 }}>
        <List disablePadding>
          {sessions.map((session) => (
            <ListItem
              key={session.session_id}
              button
              onClick={() => onSelectConversation(session.session_id)}
              sx={{
                fontSize: '16px',
                lineHeight: '24px',
                color: '#000D4D',
                pl: 0,
                fontFamily: 'Inter',
                borderRadius: '8px',
                mb: 1,
                backgroundColor:
                  session.session_id === activeSessionId ? '#E3EFFF' : 'transparent',
                '&:hover': {
                  backgroundColor: '#EFF1FD',
                },
              }}
            >
              <ChatBubbleOutlineIcon
                sx={{
                  fontSize: '18px',
                  mr: 1,
                  color: session.session_id === activeSessionId ? '#4C64D9' : '#000D4D',
                }}
              />
              {sessionMessages[session.session_id]?.slice(0, 30)}...
            </ListItem>
          ))}
        </List>
      </Box>

      {/* Smaller Quick Upload Section */}
      <Box
        sx={{
          backgroundColor: '#EFF1FD',
          borderRadius: '16px',
          border: '2px dashed rgba(76, 100, 217, 0.2)',
          p: 1,
          boxSizing: 'border-box',
          color: '#001580',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 1,
          }}
        >
          <Typography sx={{ fontWeight: 'bold', fontSize: '0.9rem' }}>
            Quick Upload
          </Typography>
          <Link
            href="#"
            sx={{
              fontSize: '0.75rem',
              color: '#4C64D9',
              textDecoration: 'none',
              fontWeight: '500',
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
          >
            See all files →
          </Link>
        </Box>

        <Box
          sx={{
            textAlign: 'center',
            color: '#4C64D9',
            fontWeight: '500',
            mb: 1,
          }}
        >
          <Box
            component="img"
            src={fileUploadIcon}
            alt="Upload Icon"
            sx={{ width: 32, height: 32, mb: 1 }}
          />
          <Typography
            sx={{
              fontSize: '0.75rem',
              color: '#001580',
              textAlign: 'center',
            }}
          >
            Drag or click to upload
          </Typography>
        </Box>

        <Typography
          sx={{
            color: '#A1A1A1',
            fontSize: '0.7rem',
          }}
        >
          no files uploaded for this patient
        </Typography>
      </Box>
    </Box>
  );
};

export default Sidebar;
